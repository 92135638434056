import React, { Component } from 'react';
import './style.scss';
import PropTypes from 'prop-types';

export default class EventInfo extends Component {
  render() {
    const { eventInfo } = this.props;

    return (
      <div id="event-info">


        {eventInfo.newDescription ? (
          <React.Fragment>
            {eventInfo.newDescription.map((item, key) => {
              return <div key={`new-description-${key}`}>
                <div className="title">{item.title}</div>
                <p>{item.explanation}</p>
              </div>;
            })}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className="title">説明</div>
            {eventInfo.explanation}
          </React.Fragment>
        )}
        {eventInfo.offerEvent ? (<></>) : (
          <React.Fragment>
            <div className="title">開催最少人数</div>
            {eventInfo.capacity + '人'}
            <div className="title">対象卒業年</div>
            {eventInfo.graduateYear}
            <div className="title">開催地域</div>
            {eventInfo.prefecture}
          </React.Fragment>
        )}
      </div>
    );
  }
}

EventInfo.propTypes = {
  eventInfo: PropTypes.object
};

import { combineReducers } from 'redux-immutable';
import { reducer as reduxFormReducer } from 'redux-form/immutable';
import example from '../containers/Example/reducer';
import presidentList from '../containers/PresidentList/reducer';
import userDetail from '../containers/PresidentDetail2/reducer';
import { connectRouter } from 'connected-react-router/immutable';
import newEvents from '../containers/NewEvent/reducer';
import waitingStudentEvents from '../containers/WaitingStudentList/reducer';
import eventsByDate from './../containers/EventByDate/reducer';
import eventsByTag from './../containers/EventByTag/reducer';
import eventDetail from 'containers/EventDetail/reducer';
import { loadingBarReducer } from 'react-redux-loading-bar';
import menu from './../containers/Header/reducer';
import account from './../containers/Account/reducer';
import login from '../containers/Login/reducer';
import resources from '../containers/Resources/reducer';
import commonPopup from '../containers/Popup/reducer';
import requestReason from '../containers/RequestEventRessonPopUp/reducer';
import requestCompleteProfile from '../components/StudentFirstJoinEventProfileForm/reducer';
import cancelRequestEventPopUp from './../containers/CancelRequestEventPopUp/reducer';
import eventHistory from './../containers/EventHistory/reducer';
import followList from './../containers/FollowList/reducer';
import redirectURNStorage from './../containers/LinkWithRedirect/reducer';
import eventListOfPresident from './../containers/EventListOfPresident/reducer';
import message from 'containers/Message/reducer';
import conversation from 'containers/Message/MessageConversation/reducer';
import greeting from 'containers/PopupGreeting/reducer';
import { getNoticeList } from 'containers/Notification/reducer';
import review from '../containers/Review/reducer';
import banners from '../components/BannerCarousel/reducer';
import bannerEvents from '../containers/EventByBanner/reducer';
import presidentReviews from '../containers/ReviewList/reducer';
import eventsManagementByStatus from '../containers/Management/EventsManagement/reducer';
import studentManagement from '../containers/Management/StudentsManagement/EventList/reducer';
import studentProfile from '../containers/EventDetail/PopupViewStudentProfile/reducer';
import requirePhoneModal from '../containers/RequirePhonePopup/reducer';
import emailChange from '../containers/EmailChange/reducer';
import seo from '../containers/Seo/reducer';
import customModal from '../containers/CustomModal/reducer';
import gochiEvent from 'containers/GochiEvent/reducer';
import plans from 'containers/Plans/reducer';
import firebaseListener from '../containers/FirebaseListener/reducer';
import studentAbsence from '../containers/AbsenceWarning/reducer';
import offerTemplate from 'containers/OfferTemplate/reducer';
import inviteToEvent from 'containers/InviteToEventModal/reducer';
import offerEvent from 'containers/OfferEventRequestModal/reducer';
import searchStudentsManagement from 'containers/Management/StudentsManagement/SearchStudents/reducer';
import campaign from 'containers/Campaign/reducer';

export function getRootReducer(history) {
  return combineReducers({
    router: connectRouter(history),
    form: reduxFormReducer,
    example: example,
    newEvents: newEvents,
    waitingStudentEvents: waitingStudentEvents,
    eventsByDate: eventsByDate,
    eventsByTag: eventsByTag,
    presidentList: presidentList,
    userDetail: userDetail,
    eventDetail: eventDetail,
    loadingBar: loadingBarReducer,
    menu: menu,
    account: account,
    login: login,
    resources: resources,
    commonPopup: commonPopup,
    requestReason,
    requestCompleteProfile,
    cancelRequestEventPopUp: cancelRequestEventPopUp,
    eventHistory: eventHistory,
    followList: followList,
    redirectURNStorage: redirectURNStorage,
    eventListOfPresident: eventListOfPresident,
    message: message,
    conversation: conversation,
    greeting: greeting,
    getNoticeList: getNoticeList,
    review: review,
    banners: banners,
    bannerEvents,
    presidentReviews,
    eventsManagementByStatus,
    studentManagement,
    studentProfile,
    requirePhoneModal,
    emailChange,
    seo,
    firebaseListener,
    studentAbsence,
    customModal,
    gochiEvent,
    offerEvent,
    plans,
    offerTemplate,
    inviteToEvent,
    searchStudentsManagement,
    campaign
  });
}
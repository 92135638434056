import React, { useState, useEffect } from 'react';
import { Modal, Tabs, Tab } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import StarRatings from 'react-star-ratings';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import Linkify from 'react-linkify';
import firebase from 'firebase/app';

import { requestEventDetail } from 'containers/EventDetail/actions';
import Button from 'components/Button';
import PresidentDetailCarousel from 'components/PresidentDetailCarousel';
import ReviewList from 'containers/ReviewList';
import { formatExternalLink } from 'utilities/browser';
import { getCurrentAccount } from 'containers/Account/actions';
import { getOfferEventDetailSuccess, responseOfferEventRequest, shownFirstOfferEventRequest } from './actions';
import './styles.scss';
import PlatinumLabel from '../../components/PlatinumLabel';

const OfferEventRequestModal = ({ location, history }) => {
  const dispatch = useDispatch();
  const { detail = {}, presidentDetail } = useSelector((state) => state.get('offerEvent').toJS());
  const { data: currentUser, token } = useSelector((state) => state.get('account').toJS());
  const [openNote, setOpenNote] = useState(false);

  const currentPath = _get(location, 'pathname', '');
  const handleSeenFirstOffer = () => {
    dispatch(shownFirstOfferEventRequest()).then(() => {
      dispatch(getCurrentAccount(token, 'app'));
    });
  };

  const handleClearOfferDetail = () => {
    dispatch(getOfferEventDetailSuccess({
      detail: {},
      presidentDetail: {}
    }));
  };

  const handleClickResponse = (isAccepted) => {
    dispatch(responseOfferEventRequest({
      eventId: _get(detail, 'objectId'),
      isAccepted
    })).then(() => {
      handleClearOfferDetail();
      if (isAccepted) {
        history.push('/messages');
      }
      if (currentPath.startsWith('/event-detail')) {
        dispatch(requestEventDetail(_get(detail, 'objectId')));
      }
    });
  };

  const handleOnClose = () => {
    handleClearOfferDetail();
    firebase.analytics().logEvent('close_offer', {
      offer_id: _get(detail, 'objectId')
    });
  };

  useEffect(() => {
    setOpenNote(!_get(currentUser, 'isShowFirstOfferEventPopup', false));
  }, [currentUser]);

  const presidentProfile = presidentDetail.presidentProfile || {};
  presidentProfile.companyUrl = formatExternalLink(presidentProfile.companyUrl);
  presidentProfile.otherUrl = formatExternalLink(presidentProfile.otherUrl);

  return (
    <Modal
      show={!_isEmpty(detail)}
      onHide={handleOnClose}
      className="offer-event-request-modal"
      backdrop="static"
      centered={true}
    >
      <img
        className="close-icon"
        alt="close"
        src="/images/icon-close.svg"
        onClick={handleOnClose}
      />
      {_get(detail, 'priority') === '1' &&
        <PlatinumLabel className="size-md" style={{ width: 'fit-content', margin: '0 auto 8px' }} />}
      <div className="offer-event-request-title">
        {_get(detail, 'jCreatedBy.presidentProfile.companyName') || _get(detail, 'jCreatedBy.displayName')}からオファーが届きました。
      </div>
      <Tabs defaultActiveKey={1} className='offer-tabs-container'>
        <Tab eventKey={1} tabClassName="tab-head" className="offer-detail-container" title="オファー">
          <div className="offer-message">
            {_get(detail, 'name')}<br />
            <Linkify
              componentDecorator={(
                decoratedHref,
                decoratedText,
                key
              ) => (
                <a href={decoratedHref} key={key} target="_blank" rel="noreferrer">
                  {decoratedText}
                </a>
              )}
            >
              {_get(detail, 'newDescription[0].explanation')}
            </Linkify><br />
          </div>
        </Tab>
        <Tab eventKey={2} tabClassName="tab-head" className='president-detail-container' title="送信者">
          <div className="president-detail-wrapper">
            <Link to={`/presidentDetail/${_get(presidentDetail, 'objectId', '')}`} target="_blank" >
              <div className='content'>
                <PresidentDetailCarousel images={presidentDetail.photos} />
                <p>写真をクリックして詳細ページを見る </p>
              </div>
            </Link>
            <div className="president-name">
              {`${presidentDetail.fullname || presidentDetail.displayName || ''}`}
            </div>
            <div className="president-reviews">
              {parseFloat(presidentDetail.star) !== 0 && (
                <div className='rating-number'>{presidentDetail.star}</div>
              )}
              <StarRatings
                starRatedColor='#FF8C00'
                starDimension='20px'
                svgIconViewBox='0 0 35 32'
                // eslint-disable-next-line max-len
                svgIconPath='M17.5 25.718L28.315 32l-2.87-11.84L35 12.194l-12.582-1.028L17.5 0l-4.918 11.166L0 12.194l9.555 7.966L6.685 32z'
                starEmptyColor='#C8C8C8'
                starSpacing='2px'
                rating={presidentDetail.star ? parseFloat(presidentDetail.star) : 0}
                numberOfStars={5} />
              <div className='rating-times'>
                ({presidentDetail.reviewedTimes > 0 ? `${presidentDetail.reviewedTimes}件のレビュー` : 'レビューなし'})
              </div>
            </div>
            {(presidentProfile && presidentProfile.slogan) ? (
              <div className="president-quotes" >
                <div><img className="quotes-image" src="/images/quotes.png" alt="“ " /></div>
                <p className="quotes-content">{presidentProfile.slogan}</p>
              </div>
            ) : null}
            <div className="hash-container">
              {presidentDetail.tags && presidentDetail.tags.map(tag => (
                <div key={tag} className="hash-content">{tag}</div>
              ))}
            </div>
          </div>
        </Tab>
        <Tab eventKey={3} tabClassName="tab-head" title="レビュー">
          <ReviewList currentUser={presidentDetail} isShow />
        </Tab>
      </Tabs>
      <div className="btn-actions-group">
        <Button onClick={() => handleClickResponse(true)} className='accept-btn'>承認する</Button>
        <div onClick={() => handleClickResponse(false)} className='reject-btn'>辞退する</div>
      </div>
      {openNote && (
        <div className='first-time-note-overlay'>
          <img
            className="close-icon"
            alt="close"
            src="/images/icon-close.svg"
            onClick={() => {
              setOpenNote(false);
              handleSeenFirstOffer();
            }}
          />
          <div className='first-time-note'>
            オファーが届きました。<br />
            オファーの使い方は <br />
            <a
              href="https://careertrip.jp/?p=11106&post_type=shachomeshi_guide&preview=1&_ppp=549b6e0f7c"
              rel="noopener noreferrer"
              target="_blank"
            >
              こちらのリンクをご確認ください。
            </a>
          </div>
        </div>
      )}
    </Modal>

  );
};

OfferEventRequestModal.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object
};

export default withRouter(OfferEventRequestModal);